import { Model } from 'pinia-orm'
import { Bool, Num, Str } from 'pinia-orm/decorators'

export default class BoatClass extends Model {
    static entity = 'classes'

    @Num( 0, { notNullable: true } ) declare id: number
    @Str( '', { notNullable: true } ) declare name: string
    @Str( '', { notNullable: true } ) declare shortcut: string
    @Str( null ) declare flag: string | null
    @Num( null ) declare crewSize: number | null
    @Str( null ) declare scoringSystem: string | null
    @Bool( null ) declare useBoatNames: boolean | null
    @Bool( null ) declare useHandicaps: boolean | null
    @Num( 0, { notNullable: true } ) declare ageFrom: number
    @Num( null ) declare ageTo: number | null

    static piniaOptions = {
        persist: true
    }
}