import { Repository } from 'pinia-orm'
import BoatClass from '@/orm/models/BoatClass'

export default class BoatClassesRepo extends Repository {
    use = BoatClass

    classesList ( event = null ) {
        let list = this.all()

        if ( event != null ) {
            const eventClasses = event.classes.map( ( eventClass ) => ( eventClass.boatClassId ) )

            list = list.filter( ( boatClass ) => ( eventClasses.indexOf( boatClass.id ) == -1 ) )
        }

        return list.map( ( boatClass ) => ( { label: boatClass.name, shortcut: boatClass.shortcut, code: boatClass.id, value: boatClass.id } ) )
            .sort( ( a, b ) => a.label.localeCompare( b.label ) )
    }
}