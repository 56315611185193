import Club from "@/orm/models/Club"
import EventClass from "@/orm/models/EventClass"
import Venue from "@/orm/models/Venue"
import { DateFormat, ds, ts } from "@/plugins/i18n-formatted"
import { Model } from "pinia-orm"
import { DateCast } from "pinia-orm/casts"
import { Attr, BelongsTo, Bool, Cast, HasMany, Num, OnDelete, Str } from "pinia-orm/decorators"

export default class Event extends Model {
    static entity = 'events'

    @Num( 0, { notNullable: true } ) declare id: number
    @Str( '', { notNullable: true } ) declare title: string
    @Num( 0, { notNullable: true } ) declare ctlId: number
    @Cast( () => DateCast ) @Attr( '' ) declare from: string
    @Cast( () => DateCast ) @Attr( null ) declare to: string | null
    @Num( null ) declare clubId: number | null
    @BelongsTo( () => Club, 'clubId' ) declare club: Club | null
    @Str( null ) declare organizer: string | null
    @Num( null ) declare venueId: number | null
    @BelongsTo( () => Venue, 'venueId' ) declare venue: Venue | null
    @Str( null ) declare otherVenue: string | null
    @Str( null ) declare registrationUrl: string | null
    @Str( null ) declare noticeboardUrl: string | null
    @Str( null ) declare description: string | null
    @HasMany( () => EventClass, 'eventId' ) @OnDelete( 'cascade' ) declare classes: EventClass[]
    @Str( null ) declare director: string | null

    @Cast( () => DateCast ) @Attr( '' ) declare created: string
    @Cast( () => DateCast ) @Attr( '' ) declare lastOpened: string
    @Num( 0 ) declare races: number
    @Str( 'EventConfigurationTab' ) declare currentTab: string
    @Bool( false ) declare ignoreUpdateMembers: boolean

    get fullTitle () {
        return ts( 'ctl', { ctlId: this.ctlId } ) + ' ' + this.title + ' (' + this.dateRange + ')'
    }

    get dateRange () {
        return ds( new Date( this.from ), ( this.to != null && !this.from.isSameAs( this.to ) ) ? DateFormat.EventFrom : DateFormat.EventTo )
            + ( ( this.to == null || this.from.isSameAs( this.to ) ) ? '' : ' – ' + ds( new Date( this.to ), DateFormat.EventTo ) )
    }

    get orderedClasses () {
        return this.classes.sort( ( a, b ) => ( a.weight == b.weight ? a.shortcut.localeCompare( b.shortcut ) : b.weight - a.weight ) )
    }

    get venueTitle () {
        return this.venueId != null ? this.venue?.localizedName : this.otherVenue
    }

    get clubTitle () {
        return this.clubId != null ? this.club?.id + ' ' + this.club?.name : this.organizer
    }

    classAttended ( boatClassId ) {
        return this.classes.find( ( eventClass ) => ( eventClass.boatClassId === boatClassId ) )
    }

    static piniaOptions = {
        persist: true
    }
}
