import { Plugin } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import MainPage from "@/components/screens/MainPage.vue"
import EventManager from '@/components/screens/EventManager.vue'
import NotFound from '@/components/NotFound.vue'
import NewEvent from '@/components/screens/NewEvent.vue'
import SelectEvent from '@/components/screens/SelectEvent.vue'
import DevNotes from "@/components/screens/DevNotes.vue"
import RecentEvents from "@/components/screens/RecentEvents.vue"
import AppSettings from "@/components/screens/AppSettings.vue"
import EventConfigurationTab from "@/components/tabs/EventConfigurationTab.vue"
import EventManagementTab from "@/components/tabs/EventManagementTab.vue"
import EventStartListTab from "@/components/tabs/EventStartListTab.vue"
import EventNoticeboardTab from "@/components/tabs/EventNoticeboardTab.vue"
import EventRaceTab from "@/components/tabs/EventRaceTab.vue"
import EventResultsTab from "@/components/tabs/EventResultsTab.vue"
import EventLastTab from "@/components/tabs/EventLastTab.vue"

export const router: Plugin = ( app ) => {
    const routes = [
        { path: '/', name: 'MainPage', component: MainPage },
        { path: '/event/new', name: 'NewEvent', component: NewEvent },
        { path: '/event/select', name: 'SelectEvent', component: SelectEvent },
        { path: '/event/list', name: 'RecentEvents', component: RecentEvents },
        {
            path: '/event/:eventId(\\d+)', component: EventManager,
            children: [
                { path: '', name: 'EventManager', component: EventLastTab },
                { path: 'conf', name: 'EventConfigurationTab', component: EventConfigurationTab },
                { path: 'manage', name: 'EventManagementTab', component: EventManagementTab },
                { path: 'start', name: 'EventStartListTab', component: EventStartListTab },
                { path: 'noticeboard', name: 'EventNoticeboardTab', component: EventNoticeboardTab },
                { path: 'race/:raceId(\\d+)?', name: 'EventRaceTab', component: EventRaceTab },
                { path: 'results', name: 'EventResultsTab', component: EventResultsTab },
                { path: ':pathMatch(.+)', name: 'EventNotFound', component: NotFound }
            ]
        },
        { path: '/devnotes', name: 'DevNotes', component: DevNotes },
        { path: '/settings', name: 'AppSettings', component: AppSettings },
        { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }
    ]

    const myRouter = createRouter( {
        history: createWebHistory(),
        routes
    } )

    app.use( myRouter )
}
