<template>
    <common-page
        :label="ts( 'createEvent' )" :ok-btn="ts( 'createNewEvent' )" :use-second-button="false"
        @ok="createEvent()">
        <q-input
            v-model="ctl" autofocus :label="ts( 'ctlId' )" :rules="[ ( val ) => ( val >= ctlMin && val <= ctlMax ) || ctlBadId ]"
            maxlength="6" inputmode="numeric" @keydown.enter="titleField.focus()" />

        <q-input
            ref="titleField" v-model="title" type="text" :label="ts( 'eventTitle' )"
            @keydown.enter="eventFromField.focus()" />

        <q-input
            ref="eventFromField" v-model="eventFrom" type="date" :label="ts( 'eventFrom' )"
            @keydown.enter="eventToField.focus()" />

        <q-input
            ref="eventToField" v-model="eventTo" type="date" :label="ts( 'eventTo' )"
            @keydown.enter="venueField.focus()" />

        <q-select
            ref="venueField" v-model="venue" :options="venuesFilteredList" :label="ts( 'venue' )" option-value="value" option-label="label"
            use-input emit-value map-options input-debounce="300"
            @filter="venueFilter"
            @keydown.enter="chooseOnlyOneVenue()"
            @update:model-value="venueId => settingsRepo.setOption( Options.PreferredVenue, venueId )" />
    </common-page>
</template>

<script setup lang="ts">
// Purpose: create new event manually

import Confirmation from "@/components/dialogs/Confirmation.vue"
import CommonPage from "@/components/parts/commonPage.vue"
import { Defaults, Options } from "@/orm/models/Setting"
import EventsRepo from "@/orm/repositories/EventsRepo"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import VenuesRepo from "@/orm/repositories/VenuesRepo"
import { ts } from "@/plugins/i18n-formatted"
import { useRepo } from "pinia-orm"
import { useQuasar } from "quasar"
import { computed, ref } from "vue"
import { useRouter } from "vue-router"

// CTL limits
const today = new Date()
const ctlMin = ( today.getFullYear() - 2000 ) * 10000
const ctlMax = ctlMin + 9999
const ctlBadId = ts( 'badCtlId' )

// Data layer
const ctl = ref( null )
const title = ref( '' )
const eventFrom = ref( today.toSimpleDate() )
const eventTo = ref( today.toSimpleDate() )

const titleField = ref()
const eventFromField = ref()
const eventToField = ref()
const venueField = ref()

const quasar = useQuasar()
const router = useRouter()

// Repositories
const venuesRepo = computed( () => ( useRepo( VenuesRepo ) ) )
const settingsRepo = computed( () => ( useRepo( SettingsRepo ) ) )

const venuesFilteredList = ref( venuesRepo.value.venuesList() )

const venue = ref( settingsRepo.value.getOption( Options.PreferredVenue, Defaults.PreferredVenue ) )

function venueFilter ( val, update ) {
    if ( val === '' ) {
        update( () => {
            venuesFilteredList.value = venuesRepo.value.venuesList()
        } )
        return
    }

    update( () => {
        const needle = val.toSearchable()
        venuesFilteredList.value = venuesRepo.value.venuesList().filter( event => event.label.toSearchable().indexOf( needle ) > -1 )
    } )
}

function createEvent ( force = false ) {
    if ( ctl.value == null )
        return

    if ( !force && useRepo( EventsRepo ).eventByCtlId( ctl.value ) != null ) {
        quasar.dialog( {
                component: Confirmation,
                componentProps: {
                    icon: 'event',
                    question: ts( 'dupEventConfirm', { ctlId: ctl.value, title: title.value } ),
                    yes: ts( 'createNewOne' ),
                    no: ts( 'openRecent' )
                }
            } )
            .onOk( () => ( createEvent( true ) ) )
            .onCancel( () => ( justOpen() ) )
    } else {
        const storedEvent = useRepo( EventsRepo ).createEvent( {
            title: title.value,
            ctlId: parseInt( ctl.value ),
            from: new Date( eventFrom.value ),
            venueId: venue.value
        } )

        if ( eventTo.value != null )
            storedEvent.to = new Date( eventTo.value )

        useRepo( EventsRepo ).save( storedEvent )

        router.push( { name: 'EventManager', params: { eventId: storedEvent.id } } )
    }
}

function justOpen () {
    if ( ctl.value == null )
        return

    const storedEvent = useRepo( EventsRepo ).eventByCtlId( ctl.value )

    if ( storedEvent != null )
        router.push( { name: 'EventManager', params: { eventId: storedEvent.id } } )
}

function chooseOnlyOneVenue ( andClose = true ) {
    if ( venuesFilteredList.value != null && venuesFilteredList.value.length == 1 ) {
        venue.value = venuesFilteredList.value[ 0 ].value

        if ( andClose )
            createEvent()
    }
}
</script>
