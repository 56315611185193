<template>
    <q-drawer show-if-above side="left" elevated class="bg-grey-8 text-white">
        <q-scroll-area class="fit sidebar" :content-style="{ 'display': 'flex', 'flex-direction': 'column' }">
            <div class="row sidebar-space">
                <div class="col-12 flex column justify-between">
                    <div>
                        <q-list padding>
                            <qe-side-bar-item icon="ads_click" :label="ts( 'selectEvent' )" :to="{ name: 'SelectEvent' }" />
                            <qe-side-bar-item icon="dashboard_customize" :label="ts( 'createEvent' )" :to="{ name: 'NewEvent' }" />

                            <qe-side-bar-separator />

                            <qe-side-bar-item
                                v-for="event in lastEvents"
                                :key="event.id" icon="emoji_events" :label="event?.title + ' (' + event?.ctlId + ')'"
                                :to="{ name: 'EventManager', params: { eventId: event.id } }" />

                            <qe-side-bar-item v-if="isArchive" icon="history" :label="ts( 'recentEvents' )" :to="{ name: 'RecentEvents' }" />

                            <qe-side-bar-separator v-if="lastEvents.length > 0" />

                            <qe-side-bar-item icon="settings" :label="ts( 'settings' )" :to="{ name: 'AppSettings' }" />
                        </q-list>
                    </div>
                    <div>
                        <q-list padding>
                            <qe-side-bar-item
                                v-if="isAuthenticated" icon="logout" :label="ts( 'logout' )"
                                @click="emits( 'logOut' )" />

                            <qe-side-bar-item
                                v-else icon="login" :label="ts( 'login' )"
                                @click="emits( 'logIn' )" />

                            <qe-side-bar-item
                                icon="refresh" :label="ts( 'updateData' )"
                                :title="lastDataUpdate ? ts( 'dataUpdatedAt', { 'ts': ds( new Date( lastDataUpdate ), DateFormat.DateTimeLong ) } ) : ts( 'lastSailingDataUpdatedNever' )"
                                @click="emits( 'syncData' )" />

                            <qe-side-bar-item v-if="inDevEnv" icon="developer_mode" :label="ts( 'dev' )" :to="{ name: 'DevNotes' }" />
                        </q-list>
                    </div>
                </div>
            </div>
        </q-scroll-area>
    </q-drawer>
</template>

<script setup lang="ts">
import QeSideBarItem from "@/components/qe/qeSideBarItem.vue"
import QeSideBarSeparator from "@/components/qe/qeSideBarSeparator.vue"
import { Defaults, Options } from "@/orm/models/Setting"
import EventsRepo from "@/orm/repositories/EventsRepo"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import { DateFormat, ds, ts } from "@/plugins/i18n-formatted"
import { useRepo } from "pinia-orm"
import { computed } from "vue"

const emits = defineEmits( [ 'syncData', 'logIn', 'logOut' ] )

const lastEvents = computed( () => ( useRepo( EventsRepo ).orderedListLimited() ) )
const isArchive = computed( () => ( useRepo( EventsRepo ).moreThanLimited() ) )
const inDevEnv = computed( () => ( import.meta.env.DEV ) )
const lastDataUpdate = computed( () => ( useRepo( SettingsRepo ).getOption( Options.LastSailingDataUpdated, Defaults.LastSailingDataUpdated ) ) )
const isAuthenticated = computed( () => ( useRepo( SettingsRepo ).getOption( Options.AuthToken, Defaults.AuthToken ) != '' ) )
</script>

<style scoped lang="sass">
.sidebar-space
  min-height: 100%
  flex-grow: 1
</style>