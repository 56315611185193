import { Defaults, Options } from "@/orm/models/Setting"
import MembersRepo from "@/orm/repositories/MembersRepo"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import { useRepo } from "pinia-orm"
import apiRequest from "./httpClient"

export function updateMembersList () {
    const settingsRepo = useRepo( SettingsRepo )

    if ( settingsRepo.getOption( Options.AuthToken, Defaults.AuthToken ).length > 0 ) {
        return apiRequest( settingsRepo.getOption( Options.AuthToken, Defaults.AuthToken ) ).get( '/catalogs/members' )
            .then( response => {
                useRepo( MembersRepo ).flush()

                response.data.forEach( ( line ) => {
                    const member = useRepo( MembersRepo ).make( {
                        id: parseInt( line.id ),
                        firstName: String( line.firstName ),
                        lastName: String( line.lastName )
                    } )

                    if ( Object.prototype.hasOwnProperty.call( line, 'birthYear' ) )
                        member.birthYear = Number( line.birthYear )

                    useRepo( MembersRepo ).save( member )
                } )
            } )
    } else {
        return Promise.allSettled( [] )
    }
}
