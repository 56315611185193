<template>
</template>

<script setup lang="ts">
import EventsRepo from "@/orm/repositories/EventsRepo"
import { useRepo } from "pinia-orm"
import { computed } from "vue"
import { useRoute, useRouter } from "vue-router"

const route = useRoute()
const router = useRouter()

const event = computed( () => ( useRepo( EventsRepo ).getEvent( String( route.params.eventId ) ) ) )

useRepo( EventsRepo ).selectEvent( event.value.id )

async function solveRedirect ( where ) {
    if ( where.match( /-\d+$/ ) ) {
        const parts = where.split( '-' )
        const failure = await router.replace( { name: parts[ 0 ], params: { raceId: parts[ 1 ] } } )

        if ( failure )
            router.replace( { name: 'EventConfigurationTab' } )
    } else {
        const failure = await router.replace( { name: where } )

        if ( failure )
            router.replace( { name: 'EventConfigurationTab' } )
    }
}

solveRedirect( event.value.currentTab )
</script>