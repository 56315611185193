<template>
    <common-page
        :label="ts( 'dev' )" :caption="ts( 'futurePlan' )" :cancel-btn="ts( 'dumpStores' )" :ok-btn="ts( 'clearMemory' )"
        @cancel="dumpStores()"
        @ok="clearStores()">
        <ul>
            <li>Refaktoring komponent před dalšími dialogy</li>
            <li>Validace dat ve formuláři definice závodů (povinné CTL ID, eventFrom, title aj. omezení)</li>
            <li>Startovní listina</li>
            <li>Kde budeme definovat rozhodčí a záchranáře? Další sekce definice závodu?</li>
            <li>Promyslet práci s přidáváním odlišné lokace a odlišného pořadatele než z číselníku</li>
            <li>Pokud mám aktualizaci starší než 3 dny - zeptej se, zda nechce iniciovat aktualizaci</li>
            <li>Co když vypršelo přihlášení, jak to poznám a co udělám?</li>
            <li>Sentry je blokováno uBlockem - chce to řešit tunelovou službou na api.sailing.cz</li>
            <li>NETUŠÍM JAK: Formát datumu ve formulářích NewEvent a SelectEvent</li>
            <li>NETUŠÍM JAK: SideBar - konzolová hláška o reaktivitě</li>
        </ul>
    </common-page>
</template>

<script setup lang="ts">
import CommonPage from "@/components/parts/commonPage.vue"
import CtlClass from "@/orm/models/CtlClass"
import EventClass from "@/orm/models/EventClass"
import BoatClassesRepo from "@/orm/repositories/BoatClassesRepo"
import ClubsRepo from "@/orm/repositories/ClubsRepo"
import CtlRepo from "@/orm/repositories/CtlRepo"
import EventsRepo from "@/orm/repositories/EventsRepo"
import MembersRepo from "@/orm/repositories/MembersRepo"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import VenuesRepo from "@/orm/repositories/VenuesRepo"
import { ts } from "@/plugins/i18n-formatted"
import { useDumpStore } from "@/utils/dumpStores"
import { useRepo } from "pinia-orm"

const dumpStore = useDumpStore()

function dumpStores () {
    console.log( dumpStore.all() )
}

const settingsRepo = useRepo( SettingsRepo )

settingsRepo.setOption( 'test', 1 )

function clearStores () {
    useRepo( SettingsRepo ).flush()
    useRepo( CtlRepo ).flush()
    useRepo( CtlClass ).flush()
    useRepo( EventsRepo ).flush()
    useRepo( EventClass ).flush()
    useRepo( MembersRepo ).flush()
    useRepo( VenuesRepo ).flush()
    useRepo( BoatClassesRepo ).flush()
    useRepo( ClubsRepo ).flush()
}
</script>
