import { Plugin } from 'vue'

// Styles (fonts, icons, basic styles)
import '@quasar/extras/roboto-font-latin-ext/roboto-font-latin-ext.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css'
import '@quasar/extras/material-symbols-outlined/material-symbols-outlined.css'
import '@quasar/extras/material-symbols-rounded/material-symbols-rounded.css'
import '@quasar/extras/material-symbols-sharp/material-symbols-sharp.css'

import 'quasar/src/css/index.sass'

// Quasar
import { Quasar, Dialog } from 'quasar'
import quasarLang from 'quasar/lang/cs'

export const viewlayer: Plugin = ( app ) => {
	app.use( Quasar, {
		plugins: {
			Dialog
		},
		lang: quasarLang
	} )
}
