import { localization } from "@/plugins/i18n"
import { Model } from 'pinia-orm'
import { Str, Num } from 'pinia-orm/decorators'

export default class Venue extends Model {
    static entity = 'venues'

    @Num( 0, { notNullable: true } ) declare id: number
    @Str( '', { notNullable: true } ) declare name: string
    @Str( null ) declare nameEn: string | null
    @Str( null ) declare geoLat: string | null
    @Str( null ) declare getLng: string | null
    @Str( null ) declare country: string | null
    @Num( null ) declare maxCircuits: number | null

    get localizedName () {
        return ( localization.global.locale == 'en' && this.nameEn != null ) ? this.nameEn : this.name
    }

    static piniaOptions = {
        persist: true
    }
}