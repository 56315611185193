// Basic string functions

declare global {
    interface String {
        toAscii (): string
        toSearchable (): string
        isNumeric (): boolean
    }
}

String.prototype.toAscii = function () {
    return String( this ).normalize( "NFD" ).replace( /\p{Diacritic}/gu, "" )
}

String.prototype.toSearchable = function () {
    return String( this ).toAscii().toLowerCase()
}

String.prototype.isNumeric = function () {
    return ( typeof ( this ) === 'number' || typeof ( this ) === "string" && this.trim() !== '' ) && !isNaN( this as number )
}
