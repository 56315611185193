import Ctl from "@/orm/models/Ctl"
import { Repository } from 'pinia-orm'

export default class CtlRepo extends Repository {
    use = Ctl

    hasEvents () {
        return this.all().length > 0
    }

    eventsList () {
        return this.all()
            .map( ( event ) => ( {
                label: event.id + ' ' + event.name,
                value: event.id,
                venue: event.venueId,
                from: new Date( event.from ),
                to: event.to == null ? null : new Date( event.to )
            } ) )
            .sort( ( a, b ) => a.label.localeCompare( b.label ) )
    }
}