import { Defaults, Options } from "@/orm/models/Setting"
import ClubsRepo from "@/orm/repositories/ClubsRepo"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import { useRepo } from "pinia-orm"
import apiRequest from "./httpClient"

export function updateClubsList () {
    const settingsRepo = useRepo( SettingsRepo )

    return apiRequest( settingsRepo.getOption( Options.AuthToken, Defaults.AuthToken ) ).get( '/catalogs/clubs' ).then( response => {
        useRepo( ClubsRepo ).flush()

        response.data.forEach( ( line ) => {
            const club = useRepo( ClubsRepo ).make( {
                id: parseInt( line.id ),
                name: String( line.name )
            } )

            if ( Object.prototype.hasOwnProperty.call( line, 'street' ) )
                club.street = String( line.street )

            if ( Object.prototype.hasOwnProperty.call( line, 'city' ) )
                club.city = String( line.city )

            if ( Object.prototype.hasOwnProperty.call( line, 'zip' ) )
                club.zip = String( line.zip )

            if ( Object.prototype.hasOwnProperty.call( line, 'manager' ) )
                club.manager = String( line.manager )

            if ( Object.prototype.hasOwnProperty.call( line, 'email' ) )
                club.email = String( line.email )

            useRepo( ClubsRepo ).save( club )
        } )
    } )
}
