import { Model } from 'pinia-orm'
import { Str, Uid } from 'pinia-orm/decorators'

export enum EventListOrder {
    LastOpened,
    Created,
    EventTerm
}

export enum Language {
    Czech,
    English
}

export const Options = {
    RaceShown: 'raceShown',
    EventListOrder: 'eventListOrder',
    Language: 'lang',
    PreferredVenue: 'preferredVenue',
    SelectEventFrom: 'selectEventFrom',
    SelectEventTo: 'selectEventTo',
    LastSailingDataUpdated: 'lastSailingDataUpdated',
    SailingDataLoadingState: 'sailingDataLoadingState',
    AuthToken: 'authToken',
    AuthFullName: 'authFullName',
    SeqEvent: 'seqEvent'
}

export const Defaults = {
    RaceShown: 5,
    EventListOrder: EventListOrder.LastOpened,
    Language: Language.Czech,
    PreferredVenue: null,
    SelectEventFrom: null,
    SelectEventTo: null,
    LastSailingDataUpdated: null,
    SailingDataLoadingState: 'false',
    AuthToken: '',
    AuthFullName: '',
    SeqEvent: '0'
}

export default class Setting extends Model {
    static entity = 'settings'

    @Uid() declare id: string
    @Str( '', { notNullable: true } ) declare option: string
    @Str( '', { notNullable: true } ) declare value: string

    static piniaOptions = {
        persist: true
    }
}