import { Pinia, Store, getActivePinia } from 'pinia'

interface ExtendedPinia extends Pinia {
    _s: Map<string, Store>
}

export function useDumpStore () {
    const pinia = getActivePinia() as ExtendedPinia

    if ( !pinia ) {
        throw new Error( 'There is no active Pinia instance' )
    }

    const dumpStore: Record<string, () => void> = {}

    dumpStore.all = () => Array.from( pinia._s.values() )

    return dumpStore
}