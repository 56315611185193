<template>
    <q-item clickable v-ripple class="q-pt-sm q-pb-sm q-pl-md q-pr-md" active-class="bg-white text-grey-9 font-bolder">
        <q-item-section avatar>
            <q-icon :name="props.icon" />
        </q-item-section>
        <q-item-section>
            {{ props.label }}
        </q-item-section>
    </q-item>
</template>

<script setup lang="ts">
interface Props {
    icon: String,
    label: String,
}

const props = defineProps<Props>()
</script>

<style scoped lang="sass">
.font-bolder
  font-weight: bold
</style>