<template>
    <q-tab-panel name="EventConfigurationTab">
        <q-card-section v-if="editMode == EditMode.EditDetails">
            <qe-form>
                <qe-input
                    v-model="ctlEditable" autofocus :label="ts( 'ctlId' )" :rules="[ ( val ) => ( val >= ctlMin && val <= ctlMax ) || ctlBadId ]"
                    maxlength="6" inputmode="numeric" />

                <qe-input v-model="titleEditable" type="text" :label="ts( 'eventTitle' )" />
                <qe-input v-model="eventFromEditable" type="date" :label="ts( 'eventFrom' )" />
                <qe-input v-model="eventToEditable" type="date" :label="ts( 'eventTo' )" />

                <qe-combo-box
                    v-model="venueEditable" :options="venuesFilteredList" :label="ts( 'venue' )"
                    @filter="venueFilter" />

                <qe-combo-box
                    v-model="clubEditable" :options="clubsFilteredList" :label="ts( 'organizer' )"
                    @filter="clubFilter" />

                <qe-input v-model="directorEditable" type="text" :label="ts( 'director' )" />
                <qe-input v-model="descEditable" type="textarea" :label="ts( 'desc' )" />
            </qe-form>

            <q-card-actions align="right" class="q-mt-md">
                <qe-btn
                    :label="ts( 'cancel' )"
                    @click="editMode = EditMode.Display" />
                <qe-btn
                    :label="ts( 'save' )"
                    @click="saveValues()" />
            </q-card-actions>
        </q-card-section>

        <q-card-section v-else-if="editMode == EditMode.EditClasses">
            <q-table flat :rows="attendedClasses" :columns="classColumns" row-key="id" binary-state-sort hide-bottom :no-data-label="ts( 'noClasses' )">
                <template v-slot:body-cell-actions="props">
                    <q-td :props="props">
                        <q-btn
                            flat icon="delete" color="negative"
                            @click="removeClass( props.row )" />
                    </q-td>
                </template>
                <template v-slot:body-cell-eventType="props">
                    <q-td :props="props">
                        {{ props.row.eventTypeDesc }}
                        <q-popup-edit
                            v-model="props.row.eventTypeNormalized" v-slot="scope" buttons cover
                            @save="( val ) => ( setBoatClass( val, props.row ) )">
                            <q-select
                                v-model="scope.value" autofocus :options="eventTypesList"
                                :label="ts( 'eventTypeCoefFor', { boatClass: props.row.boatClass.name } )" option-value="value"
                                option-label="label" emit-value map-options input-debounce="300"
                                @keyup.enter="scope.set">
                                <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
                                    <q-item v-bind="itemProps" :class="opt.lastCommon ? 'group-separated-option' : ''">
                                        <q-item-section>
                                            <q-item-label v-html="opt.label" />
                                        </q-item-section>
                                    </q-item>
                                </template>
                            </q-select>
                        </q-popup-edit>
                    </q-td>
                </template>
                <template v-slot:bottom-row>
                    <q-td>
                        <q-select
                            v-model="newBoatClass" :label="ts( 'boatClass' )" :options="boatClassFilteredList" option-value="value" option-label="label"
                            use-input emit-value map-options input-debounce="300" clearable hide-selected fill-input autofocus
                            @filter="boatClassesFilter" />
                    </q-td>
                    <q-td>
                        <q-select
                            v-model="newEventType" :label="ts( 'eventTypeCoef' )" :options="eventTypesList" option-value="value"
                            option-label="label" emit-value map-options input-debounce="300">
                            <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
                                <q-item v-bind="itemProps" :class="opt.lastCommon ? 'group-separated-option' : ''">
                                    <q-item-section>
                                        <q-item-label v-html="opt.label" />
                                    </q-item-section>
                                </q-item>
                            </template>
                        </q-select>
                    </q-td>
                    <q-td class="text-center">
                        <q-btn
                            :label="ts( 'add' )" outline color="secondary"
                            @click="addNewClass()" />
                    </q-td>
                </template>
            </q-table>

            <q-card-actions align="right" class="q-mt-md">
                <q-btn outline color="primary" :label="ts( 'close' )"
                       @click="editMode = EditMode.Display" />
            </q-card-actions>
        </q-card-section>

        <div v-else>
            <q-card-section>
                <q-markup-table separator="none" flat>
                    <tbody>
                    <tr>
                        <td>{{ ts( 'ctlId' ) }}</td>
                        <td>{{ event.ctlId }}</td>
                    </tr>
                    <tr>
                        <td>{{ ts( 'eventTitle' ) }}</td>
                        <td>{{ event.title }}</td>
                    </tr>
                    <tr>
                        <td>{{ ts( 'eventTerm' ) }}</td>
                        <td>{{ event.dateRange }}</td>
                    </tr>
                    <tr>
                        <td>{{ ts( 'venue' ) }}</td>
                        <td>{{ event.venueTitle }}</td>
                    </tr>
                    <tr>
                        <td>{{ ts( 'organizer' ) }}</td>
                        <td>{{ event.clubTitle }}</td>
                    </tr>
                    <tr>
                        <td>{{ ts( 'director' ) }}</td>
                        <td>{{ event.director }}</td>
                    </tr>
                    <tr v-if="event.description != null && event.description != ''">
                        <td>{{ ts( 'desc' ) }}</td>
                        <td>{{ event.description }}</td>
                    </tr>
                    </tbody>
                </q-markup-table>
            </q-card-section>

            <q-card-actions align="right" class="q-mt-md">
                <q-btn
                    outline color="primary" :label="ts( 'editEventDetails' )"
                    @click="editDetails()" />
            </q-card-actions>

            <q-separator />

            <q-card-section>
                <q-markup-table separator="vertical" flat bordered class="q-mt-lg">
                    <tbody>
                    <tr>
                        <td v-for="eventClass in event.orderedClasses" :key="eventClass.id">{{ eventClass.boatClass?.name }}</td>
                    </tr>
                    <tr>
                        <td v-for="eventClass in event.orderedClasses" :key="eventClass.id">{{ eventClass.weight
                            }}{{ eventClass.eventType != null ? eventClass.eventType : '' }}
                        </td>
                    </tr>
                    </tbody>
                </q-markup-table>
            </q-card-section>

            <q-card-actions align="right" class="q-mt-md">
                <q-btn outline color="primary" :label="ts( 'editEventClasses' )"
                       @click="editMode = EditMode.EditClasses" />
            </q-card-actions>
        </div>
    </q-tab-panel>
</template>

<script setup lang="ts">
import Confirmation from "@/components/dialogs/Confirmation.vue"
import QeBtn from "@/components/qe/qeBtn.vue"
import QeComboBox from "@/components/qe/qeComboBox.vue"
import QeForm from "@/components/qe/qeForm.vue"
import QeInput from "@/components/qe/qeInput.vue"
import { allEventTypes } from "@/orm/catalogs/EventTypes"
import EventClass from "@/orm/models/EventClass"
import BoatClassesRepo from "@/orm/repositories/BoatClassesRepo"
import ClubsRepo from "@/orm/repositories/ClubsRepo"
import EventsRepo from "@/orm/repositories/EventsRepo"
import MembersRepo from "@/orm/repositories/MembersRepo"
import VenuesRepo from "@/orm/repositories/VenuesRepo"
import { ts } from "@/plugins/i18n-formatted"
import { useRepo } from "pinia-orm"
import { useQuasar } from "quasar"
import { computed, onMounted, ref } from "vue"
import { useRoute } from "vue-router"

enum EditMode {
    Display,
    EditDetails,
    EditClasses
}

const quasar = useQuasar()
const route = useRoute()

const emits = defineEmits( [ 'logInAndUpdate' ] )

const editMode = ref( EditMode.Display )

// CTL limits
const today = new Date()
const ctlMin = ( today.getFullYear() - 2000 ) * 10000
const ctlMax = ctlMin + 9999
const ctlBadId = ts( 'badCtlId' )

// Data layer
const ctlEditable = ref()
const titleEditable = ref()
const eventFromEditable = ref()
const eventToEditable = ref()
const venueEditable = ref()
const clubEditable = ref()
const directorEditable = ref()
const descEditable = ref()

const newBoatClass = ref()
const newEventType = ref()

const membersRepo = computed( () => ( useRepo( MembersRepo ) ) )
const venuesRepo = computed( () => ( useRepo( VenuesRepo ) ) )
const clubsRepo = computed( () => ( useRepo( ClubsRepo ) ) )
const classesRepo = computed( () => ( useRepo( BoatClassesRepo ) ) )

const event = computed( () => ( useRepo( EventsRepo ).getEvent( String( route.params.eventId ), true ) ) )

useRepo( EventsRepo ).selectEvent( event.value.id )

const venuesFilteredList = ref( venuesRepo.value.venuesList() )
const clubsFilteredList = ref( clubsRepo.value.clubsList() )

const eventTypesList = computed( () => ( allEventTypes() ) )

const attendedClasses = computed( () => ( event.value.orderedClasses ) )
const boatClassFilteredList = ref( classesRepo.value.classesList( event.value ) )

const classColumns = ref( [
    {
        name: 'className',
        required: true,
        label: ts( 'boatClass' ),
        align: 'left',
        field: row => row.boatClass?.name,
        sortable: true
    },
    {
        name: 'eventType',
        label: ts( 'eventTypeCoef' ),
        align: 'left',
        field: row => row.eventTypeDesc,
        sortable: true
    },
    {
        name: 'plannedWeight',
        required: true,
        label: ts( 'plannedWeight' ),
        align: 'center',
        field: row => row.weight,
        sortable: true,
        sortOrder: 'desc',
        sort: ( a, b ) => parseInt( a ) - parseInt( b )
    },
    {
        name: 'weight',
        label: ts( 'weight' ),
        align: 'center',
        field: row => '',
        sortable: true,
        sortOrder: 'desc',
        sort: ( a, b ) => parseInt( a ) - parseInt( b )
    },
    {
        name: 'actions',
        label: ts( 'actions' ),
        field: row => '',
        align: 'center'
    }
] )

function addNewClass () {
    if ( newBoatClass.value != null && newEventType.value != null ) {
        const eventClass = useRepo( EventClass ).save( {
            eventId: event.value.id,
            boatClassId: newBoatClass.value,
            weight: 0,
            shortcut: ''
        } )

        useRepo( EventClass ).withAll().find( eventClass.id ).eventTypeNormalized = newEventType.value

        newBoatClass.value = null
        newEventType.value = null
    }
}

function setBoatClass ( normalizedType, eventClass ) {
    eventClass.eventTypeNormalized = normalizedType
}

function removeClass ( eventClass ) {
    quasar.dialog( {
            component: Confirmation,
            componentProps: {
                icon: 'delete',
                question: ts( 'removeBoatClassFromEvent', { eventTitle: eventClass.event?.fullTitle, boatClass: eventClass.boatClass?.name } )
            }
        } )
        .onOk( () => {
            useRepo( EventClass ).destroy( eventClass.id )
        } )
}

function editDetails () {
    ctlEditable.value = event.value.ctlId
    titleEditable.value = event.value.title
    eventFromEditable.value = new Date( event.value.from ).toSimpleDate()
    eventToEditable.value = new Date( event.value.to ).toSimpleDate()
    venueEditable.value = event.value.venueId
    clubEditable.value = event.value.clubId
    directorEditable.value = event.value.director
    descEditable.value = event.value.description

    editMode.value = EditMode.EditDetails
}

function saveValues () {
    let e = { id: event.value.id }

    if ( ctlEditable.value != event.value.ctlId )
        e.ctlId = ctlEditable.value

    if ( directorEditable.value != event.value.director )
        e.director = directorEditable.value

    if ( titleEditable.value != event.value.title )
        e.title = titleEditable.value

    if ( descEditable.value != event.value.description )
        e.description = descEditable.value

    if ( venueEditable.value != event.value.venueId )
        e.venueId = venueEditable.value

    if ( clubEditable.value != event.value.clubId )
        e.clubId = clubEditable.value

    if ( new Date( eventFromEditable.value ) != new Date( event.value.from ) )
        e.from = new Date( eventFromEditable.value )

    if ( eventToEditable.value == null || eventToEditable.value == '' || event.value.to == null || new Date( eventToEditable.value ) != new Date( event.value.to ) )
        e.to = ( eventToEditable.value != null && eventToEditable.value != '' ) ? new Date( eventToEditable.value ) : null

    useRepo( EventsRepo ).save( e )

    editMode.value = EditMode.Display
}

function venueFilter ( val, update ) {
    if ( val === '' ) {
        update( () => {
            venuesFilteredList.value = venuesRepo.value.venuesList()
        } )
        return
    }

    update( () => {
        const needle = val.toSearchable()
        venuesFilteredList.value = venuesRepo.value.venuesList().filter( venue => venue.label.toSearchable().indexOf( needle ) > -1 )
    } )
}

function clubFilter ( val, update ) {
    if ( val === '' ) {
        update( () => {
            clubsFilteredList.value = clubsRepo.value.clubsList()
        } )
        return
    }

    update( () => {
        const needle = val.toSearchable()
        clubsFilteredList.value = clubsRepo.value.clubsList().filter( club => club.label.toSearchable().indexOf( needle ) > -1 )
    } )
}

function boatClassesFilter ( val, update ) {
    if ( val === '' ) {
        update( () => {
            boatClassFilteredList.value = classesRepo.value.classesList( event.value )
        } )
        return
    }

    update( () => {
        const needle = val.toSearchable()
        boatClassFilteredList.value = classesRepo.value.classesList( event.value ).filter( boatClass => boatClass.label.toSearchable().indexOf( needle ) > -1 || boatClass.shortcut.toSearchable().indexOf( needle ) > -1 || String( boatClass.code ) == needle )
    } )
}

onMounted( () => {
    if ( !event.value.ignoreUpdateMembers && !membersRepo.value.hasMembers() ) {
        quasar.dialog( {
                component: Confirmation,
                componentProps: {
                    icon: 'refresh',
                    question: ts( 'wantSyncMembers' ),
                    yes: ts( 'loginAndUpdate' ),
                    no: ts( 'notNeeded' )
                }
            } )
            .onOk( () => {
                emits( 'logInAndUpdate' )

                event.value.ignoreUpdateMembers = true
                useRepo( EventsRepo ).save( event.value )
            } )
            .onCancel( () => {
                event.value.ignoreUpdateMembers = true
                useRepo( EventsRepo ).save( event.value )
            } )
    }
} )
</script>

<style lang="sass" scoped>
.group-separated-option
  border-bottom: solid 1px darkgray
</style>