<template>
    <q-select
        ref="innerSelect" option-value="value" option-label="label"
        use-input emit-value map-options input-debounce="300" clearable hide-selected fill-input
        @popup-hide.native="changed()" />
</template>

<script setup lang="ts">
import { inject, ref } from "vue"

const innerSelect = ref()

const focusNextCounter = inject( 'focusNextCounter' )

const focus = () => {
    innerSelect.value.focus()
}

defineExpose( { focus } )

function changed () {
    focusNextCounter.value++
}
</script>