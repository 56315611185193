<template>
    <q-header elevated class="bg-white text-black">
        <q-toolbar>
            <q-toolbar-title class="row items-center justify-start">
                <q-avatar square class="col col-shrink q-mr-md cursor-hand" @click="$router.push( { name: 'MainPage' } )">
                    <img :src="csjLogo" alt="">
                </q-avatar>

                <div class="col col-grow" :title="ts( 'version', { version: appVersion } )">
                    {{ ts( 'appName' ) }}
                </div>

                <q-space />

                <div class="col text-right logged-user">
                    <div v-if="isAuthenticated" class="q-mr-lg">
                        {{ loggedUserName }}
                    </div>
                </div>
            </q-toolbar-title>
        </q-toolbar>
    </q-header>
</template>

<script setup lang="ts">
// Purpose: Create application bar

import { version } from '@/../package.json'
import csjLogo from '@/assets/csjlogo.jpg'
import { Defaults, Options } from "@/orm/models/Setting"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import { ts } from '@/plugins/i18n-formatted'
import { useRepo } from "pinia-orm"
import { computed } from "vue"

const appVersion = version

const isAuthenticated = computed( () => ( useRepo( SettingsRepo ).getOption( Options.AuthToken, Defaults.AuthToken ) != '' ) )
const loggedUserName = computed( () => ( useRepo( SettingsRepo ).getOption( Options.AuthFullName, Defaults.AuthFullName ) ) )
</script>

<style lang="sass" scoped>
.cursor-hand
  cursor: pointer

.logged-user
  font-size: 75%
</style>