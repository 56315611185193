<template>
    <q-input
        ref="innerInput"
        @keydown.enter="focusNextCounter += 1" />
</template>

<script setup type="ts">
import { inject, ref } from "vue"

const innerInput = ref()

const focusNextCounter = inject( 'focusNextCounter' )

const focus = () => {
    innerInput.value.focus()
}

defineExpose( { focus } )
</script>