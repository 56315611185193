import { Defaults, Options } from "@/orm/models/Setting"
import Venue from "@/orm/models/Venue"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import VenuesRepo from "@/orm/repositories/VenuesRepo"
import { useRepo } from "pinia-orm"
import apiRequest from "./httpClient"

export function updateVenuesList () {
    const settingsRepo = useRepo( SettingsRepo )

    return apiRequest( settingsRepo.getOption( Options.AuthToken, Defaults.AuthToken ) ).get( '/catalogs/venues' ).then( response => {
        useRepo( VenuesRepo ).flush()

        response.data.forEach( ( line ) => {
            const venue = useRepo( Venue ).make( {
                id: parseInt( line.id ),
                name: String( line.name.cs )
            } )

            if ( Object.prototype.hasOwnProperty.call( line.name, 'en' ) )
                venue.nameEn = String( line.name.en )

            if ( Object.prototype.hasOwnProperty.call( line, 'geo' ) ) {
                if ( Object.prototype.hasOwnProperty.call( line.geo, 'lat' ) )
                    venue.geoLat = String( line.geo.lat )

                if ( Object.prototype.hasOwnProperty.call( line.geo, 'lng' ) )
                    venue.geoLng = String( line.geo.lng )
            }

            if ( Object.prototype.hasOwnProperty.call( line, 'country' ) )
                venue.country = String( line.country )

            if ( Object.prototype.hasOwnProperty.call( line, 'maxCircuits' ) )
                venue.maxCircuits = parseInt( line.maxCircuits )

            useRepo( VenuesRepo ).save( venue )
        } )
    } )
}
