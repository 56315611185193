import Setting, { Defaults, Language, Options } from "@/orm/models/Setting"
import { localization } from "@/plugins/i18n"
import { Repository } from 'pinia-orm'

export default class SettingsRepo extends Repository {
    use = Setting

    getOption ( option, defaultValue = null ) {
        const value = this.where( 'option', option ).first()?.value

        return value == null ? defaultValue : ( value.isNumeric() ? parseFloat( value ) : value )
    }

    setOption ( option, value: unknown ) {
        this.destroy( this.where( 'option', option ).get().map( ( setting ) => ( setting.id ) ) )

        if ( value !== null )
            this.save( { option: option, value: String( value ) } )

        this.triggerActions( option )
    }

    triggerActions ( option ) {
        if ( option == Options.Language ) {
            localization.global.locale.value = ( this.getOption( Options.Language, Defaults.Language ) == Language.Czech ? 'cs' : 'en' )
        }
    }
}