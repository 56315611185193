import { Plugin } from 'vue'

// Main internatialization support
import { createI18n } from 'vue-i18n'

// All locale files
import enMessages from '@/locales/en.json'
import csMessages from '@/locales/cs.json'

// All locale settings
import enDateTimeFormats from '@/locales/en-datetime.json'
import csDateTimeFormats from '@/locales/cs-datetime.json'

export const localization = createI18n(
    {
        legacy: false,
        locale: 'cs',
        fallbackLocale: 'en',
        globalInjection: true,
        messages: {
            en: enMessages,
            cs: csMessages
        },
        datetimeFormats: {
            en: enDateTimeFormats,
            cs: csDateTimeFormats
        },
        pluralRules: {
            en: enPluralRules,
            cs: csPluralRules
        }
    }
)

function enPluralRules ( choice ) {
    if ( choice == 1 )
        return 0

    return 1
}

function csPluralRules ( choice, choicesLength ) {
    if ( choice === 1 )
        return 0

    if ( choice >= 2 && choice <= 4 )
        return 1

    return choicesLength < 3 ? 1 : 2
}

// Construct new localization object with predefined locale, fallback locale and all locale message strings
export const i18n: Plugin = ( app ) => {

    app.use( localization )

}
