import { eventTypeTab, normalizeEventType } from "@/orm/catalogs/EventTypes"
import BoatClass from "@/orm/models/BoatClass"
import Event from "@/orm/models/Event"
import { ts } from "@/plugins/i18n-formatted"
import { Model, useRepo } from 'pinia-orm'
import { BelongsTo, Num, Str, Uid } from 'pinia-orm/decorators'

export default class EventClass extends Model {
    static entity = 'events-classes'

    @Uid() declare id: string
    @Uid() declare eventId: string
    @BelongsTo( () => Event, 'eventId' ) declare event: Event
    @Num( 0, { notNullable: true } ) declare boatClassId: number
    @BelongsTo( () => BoatClass, 'boatClassId' ) declare boatClass: BoatClass
    @Num( 0, { notNullable: true } ) declare weight: number
    @Str( null ) declare eventType: string | null
    @Str( '', { notNullable: true } ) declare shortcut: string

    get eventTypeDesc () {
        if ( this.eventType != null && this.eventType != '' ) {
            const ident = String( this.weight ) + String( this.eventType ).toUpperCase()

            if ( Object.prototype.hasOwnProperty.call( eventTypeTab, ident ) )
                return ts( eventTypeTab[ ident ] )
        }

        if ( Object.prototype.hasOwnProperty.call( eventTypeTab, String( this.weight ) ) )
            return ts( eventTypeTab[ String( this.weight ) ] )

        return String( this.weight ) + ( this.eventType != null ? this.eventType : '' )
    }

    get eventTypeNormalized () {
        return normalizeEventType( String( this.weight ) + ( this.eventType != null ? this.eventType : '' ) )
    }

    set eventTypeNormalized ( val ) {
        const weight = val.replaceAll( /\D+/g, '' )
        const eventTypeChar = val.replace( /^\d+/, '' )

        this.weight = parseInt( weight )
        this.eventType = ( eventTypeChar == '' ? null : eventTypeChar )
        this.shortcut = this.boatClass.shortcut + '-' + String( weight ) + ( eventTypeChar != null ? eventTypeChar : '' )

        useRepo( EventClass ).save( this )
    }

    static piniaOptions = {
        persist: true
    }
}