import Club from "@/orm/models/Club"
import { Repository } from 'pinia-orm'

export default class ClubsRepo extends Repository {
    use = Club

    clubsList () {
        return this.all()
            .map( ( club ) => ( { label: club.id + ' ' + club.name, value: club.id } ) )
            .sort( ( a, b ) => a.label.localeCompare( b.label ) )
    }

}