import Club from "@/orm/models/Club"
import CtlClass from "@/orm/models/CtlClass"
import Venue from "@/orm/models/Venue"
import { DateFormat, ds } from "@/plugins/i18n-formatted"
import { Model } from 'pinia-orm'
import { DateCast } from "pinia-orm/casts"
import { Str, Num, Bool, BelongsTo, Cast, Attr, HasMany, OnDelete } from 'pinia-orm/decorators'

export default class Ctl extends Model {
    static entity = 'ctls'

    @Num( 0, { notNullable: true } ) declare id: number
    @Str( '', { notNullable: true } ) declare name: string
    @Num( null ) declare clubId: number | null
    @BelongsTo( () => Club, 'clubId' ) declare club: Club | null
    @Str( null ) declare organizer: string | null
    @Num( null ) declare venueId: number | null
    @BelongsTo( () => Venue, 'venueId' ) declare venue: Venue | null
    @Str( null ) declare otherVenue: string | null
    @Cast( () => DateCast ) @Attr( '' ) declare from: string
    @Cast( () => DateCast ) @Attr( null ) declare to: string | null
    @Num( null ) declare days: number | null
    @Str( null ) declare registrationUrl: string | null
    @Str( null ) declare noticeboardUrl: string | null
    @Str( null ) declare description: string | null
    @Bool( false ) declare cancelled: boolean
    @HasMany( () => CtlClass, 'ctlId' ) @OnDelete( 'cascade' ) declare classes: CtlClass[]

    get dateRange () {
        return ds( new Date( this.from ), ( this.to != null && !this.from.isSameAs( this.to ) ) ? DateFormat.EventFrom : DateFormat.EventTo )
            + ( ( this.to == null || this.from.isSameAs( this.to ) ) ? '' : ' – ' + ds( new Date( this.to ), DateFormat.EventTo ) )
    }

    static piniaOptions = {
        persist: true
    }
}