import CtlClass from "@/orm/models/CtlClass"
import { Defaults, Options } from "@/orm/models/Setting"
import CtlRepo from "@/orm/repositories/CtlRepo"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import VenuesRepo from "@/orm/repositories/VenuesRepo"
import { useRepo } from "pinia-orm"
import apiRequest from "./httpClient"

export function updateEventsList () {
    const settingsRepo = useRepo( SettingsRepo )

    return apiRequest( settingsRepo.getOption( Options.AuthToken, Defaults.AuthToken ) ).get( '/events/list' ).then( response => {
        useRepo( CtlRepo ).flush()
        useRepo( CtlClass ).flush()

        response.data.forEach( ( line ) => {
            const event = useRepo( CtlRepo ).make( {
                id: parseInt( line.id ),
                name: String( line.name ),
                from: new Date( line.from )
            } )

            if ( Object.prototype.hasOwnProperty.call( line, 'clubId' ) )
                event.clubId = parseInt( line.clubId )

            if ( Object.prototype.hasOwnProperty.call( line, 'organizer' ) )
                event.organizer = String( line.organizer )

            if ( Object.prototype.hasOwnProperty.call( line, 'venue' ) ) {
                const venue = String( line.venue )

                if ( venue != null && venue.length > 0 ) {
                    const venueId = useRepo( VenuesRepo ).venueIdByName( venue )

                    if ( venueId != null )
                        event.venueId = venueId
                    else
                        event.otherVenue = venue
                }
            }

            if ( Object.prototype.hasOwnProperty.call( line, 'to' ) )
                event.to = new Date( line.to )

            if ( Object.prototype.hasOwnProperty.call( line, 'days' ) ) {
                event.days = parseInt( line.days )

                if ( typeof event.to === "undefined" ) {
                    const eventTo = new Date( event.from )
                    eventTo.setDate( event.from + event.days )
                    event.to = eventTo
                }
            } else if ( typeof event.to !== "undefined" )
                event.days = Math.floor( ( event.to - event.from ) / ( 1000 * 60 * 60 * 24 ) )

            if ( Object.prototype.hasOwnProperty.call( line, 'registrationUrl' ) )
                event.registrationUrl = String( line.registrationUrl )

            if ( Object.prototype.hasOwnProperty.call( line, 'noticeboardUrl' ) )
                event.noticeboardUrl = String( line.noticeboardUrl )

            if ( Object.prototype.hasOwnProperty.call( line, 'description' ) )
                event.description = String( line.description )

            if ( Object.prototype.hasOwnProperty.call( line, 'cancelled' ) )
                event.cancelled = Boolean( line.cancelled )
            else
                event.cancelled = false

            useRepo( CtlRepo ).save( event )

            line.classes.forEach( ( element ) => {
                const eventClass = useRepo( CtlClass ).make( {
                    ctlId: event.id,
                    boatClassId: parseInt( element.classId ),
                    weight: parseInt( element.weight ),
                    shortcut: String( element.shortcut )
                } )

                if ( Object.prototype.hasOwnProperty.call( element, 'eventType' ) )
                    eventClass.eventType = String( element.eventType )

                useRepo( CtlClass ).save( eventClass )
            } )
        } )
    } )
}
