<template>
    <q-dialog
        ref="dialogRef"
        @hide="onDialogHide">
        <q-card class="q-dialog-plugin">
            <q-card-section>
                <q-form ref="form" v-model="formdata">
                    <div class="text-h5 q-mb-sm">
                        {{ ts( 'loginToCsj' ) }}
                    </div>

                    <div v-if="props.errorMsg.length > 0" class="text-negative text-weight-bold">
                        <i class="q-icon notranslate material-icons q-field__marginal text-negative q-mr-sm" aria-hidden="true" role="presentation">error</i>
                        {{ props.errorMsg }}
                    </div>

                    <q-input
                        v-model="formdata.username" name="username" type="text" autofocus :label="ts( 'username' )" autocorrect="off"
                        spellcheck="false" autocapitalize="off" :rules="[ ( val ) => ( val && val.length > 0 ) || reqfields]"
                        @keydown.enter="passwordField.focus()" />

                    <q-input
                        ref="passwordField" v-model="formdata.password" name="password" type="password" :label="ts( 'password' )"
                        autocorrect="off" spellcheck="false" autocapitalize="off" :rules="[ ( val ) => ( val && val.length > 0 ) || reqfields]"
                        @keydown.enter.prevent="onLogin()" />
                </q-form>
            </q-card-section>

            <q-card-actions align="right">
                <q-btn
                    color="primary" flat :label="ts( 'cancel' )"
                    @click="onDialogCancel" />
                <q-btn
                    ref="submitbtn" flat color="primary" type="submit" :label="ts( 'login' )"
                    @click="onLogin()" />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script setup lang="ts">
import { useDialogPluginComponent } from 'quasar'
import { ts } from '@/plugins/i18n-formatted'
import { ref } from 'vue'

const reqfields = ts( 'fieldIsRequired' )

const formdata = ref( { username: '', password: '' } )

const props = defineProps( { errorMsg: { type: String, required: true } } )

defineEmits( [ ...useDialogPluginComponent.emits ] )

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const form = ref( null )

async function onLogin () {
    const success = await form.value.validate()

    if ( success ) {
        onDialogOK( formdata.value )
    }
}

const passwordField = ref( null )
</script>