<template>
    <common-page :label="ts( 'listEvents' )" :use-buttons="false">
        <q-table flat :rows="eventsData" :columns="eventColumns" row-key="id" binary-state-sort :no-data-label="ts( 'noEvents' )">
            <template v-slot:body-cell-actions="props">
                <q-td :props="props">
                    <qe-btn
                        color="primary" :label="ts( 'open' )"
                        @click="openEvent( props.row.id )" />
                </q-td>
            </template>
        </q-table>
    </common-page>
</template>

<script setup lang="ts">
import CommonPage from "@/components/parts/commonPage.vue"
import QeBtn from "@/components/qe/qeBtn.vue"
import EventsRepo from "@/orm/repositories/EventsRepo"
import { ts } from "@/plugins/i18n-formatted"
import { useRepo } from "pinia-orm"
import { computed } from "vue"
import { useRouter } from "vue-router"

const router = useRouter()

const eventsData = computed( () => useRepo( EventsRepo ).orderedListAll() )

const eventColumns = [
    {
        name: 'ctlId',
        required: true,
        label: ts( 'ctl' ),
        align: 'center',
        field: row => row.ctlId,
        sortable: true
    },
    {
        name: 'title',
        required: true,
        label: ts( 'eventTitle' ),
        align: 'left',
        field: row => row.title,
        sortable: true
    },
    {
        name: 'dateRange',
        required: true,
        label: ts( 'eventTerm' ),
        align: 'right',
        field: row => row.dateRange,
        sortable: true
    },
    {
        name: 'lastOpened',
        required: true,
        label: ts( 'recent' ),
        align: 'right',
        field: row => new Date( row.lastOpened ).verb(),
        sortable: true
    },
    {
        name: 'actions',
        required: true,
        label: ts( 'actions' ),
        align: 'center',
        field: () => '',
        sortable: false
    }
]

function openEvent ( eventId ) {
    if ( eventId != null )
        router.push( { name: 'EventManager', params: { eventId: eventId } } )
}
</script>
