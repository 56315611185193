import Event from "@/orm/models/Event"
import { Defaults, EventListOrder, Options } from "@/orm/models/Setting"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import { Repository, useRepo } from 'pinia-orm'

export default class EventsRepo extends Repository {
    use = Event

    selectEvent ( id ) {
        const event = this.find( id )

        if ( event == null )
            return

        event.lastOpened = new Date()

        if ( event.currentTab == null )
            event.currentTab = 'EventConfigurationTab'

        this.save( event )
    }

    getEvent ( id, details = false ) {
        const event = details ? this.where( 'id', id ).withAllRecursive().first() : this.find( id )

        if ( event == null )
            return null

        if ( event.currentTab == null )
            event.currentTab = 'EventConfigurationTab'

        return event
    }

    eventByCtlId ( ctlId ) {
        return this.where( 'ctlId', ctlId ).first()
    }

    orderedListAll () {
        const eventListOrder = parseInt( useRepo( SettingsRepo ).getOption( Options.EventListOrder, Defaults.EventListOrder ) )
        const field = eventListOrder == EventListOrder.Created ? 'created' : ( eventListOrder == EventListOrder.LastOpened ? 'lastOpened' : 'from' )

        return this.orderBy( event => new Date( event[ field ] ).toISOString(), 'desc' ).get()
    }

    orderedListLimited () {
        const eventListOrder = parseInt( useRepo( SettingsRepo ).getOption( Options.EventListOrder, Defaults.EventListOrder ) )
        const raceShown = parseInt( useRepo( SettingsRepo ).getOption( Options.RaceShown, Defaults.RaceShown ) )
        const field = eventListOrder == EventListOrder.Created ? 'created' : ( eventListOrder == EventListOrder.LastOpened ? 'lastOpened' : 'from' )

        return this.orderBy( event => new Date( event[ field ] ).toISOString(), 'desc' ).limit( raceShown ).get()
    }

    moreThanLimited () {
        const raceShown = useRepo( SettingsRepo ).getOption( Options.RaceShown, Defaults.RaceShown )

        return this.all().length > raceShown
    }

    createEvent ( event ) {
        const storedEvent = this.save( {
            ...event,
            id: parseInt( useRepo( SettingsRepo ).getOption( Options.SeqEvent, Defaults.SeqEvent ) ) + 1,
            created: new Date(),
            lastOpened: new Date()
        } ) as Event

        useRepo( SettingsRepo ).setOption( Options.SeqEvent, String( storedEvent.id ) )

        return storedEvent
    }

}