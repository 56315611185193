import { updateClassesList } from "@/network/classes"
import { updateClubsList } from "@/network/clubs"
import { updateEventsList } from '@/network/events'
import { updateMembersList } from "@/network/members"
import { updateVenuesList } from "@/network/venues"
import { Defaults, Options } from "@/orm/models/Setting"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import { useRepo } from "pinia-orm"

// Complex updater called when data is necessary
export async function updater () {
    const settingsRepo = useRepo( SettingsRepo )

    // Just one run during the time (semaphore)
    if ( settingsRepo.getOption( Options.SailingDataLoadingState, Defaults.SailingDataLoadingState ) == 'true' )
        return

    settingsRepo.setOption( Options.SailingDataLoadingState, 'true' )

    // Start asynchronous processing of all catalogs and CTL (event list for this year), wait for every single to finish
    // First set - basic catalogs
    await Promise.allSettled( [ updateClubsList(), updateClassesList(), updateVenuesList() ] )

    // Second set - dependant for existing ones
    await Promise.allSettled( [ updateEventsList(), updateMembersList() ] )

    // Last but not least - update timestamp in general store and clear loading semaphore
    settingsRepo.setOption( Options.LastSailingDataUpdated, new Date() )
    settingsRepo.setOption( Options.SailingDataLoadingState, 'false' )
}