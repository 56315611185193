import { Model } from 'pinia-orm'
import { Num, Str } from 'pinia-orm/decorators'

export default class Member extends Model {
    static entity = 'members'

    @Num( 0, { notNullable: true } ) declare id: number
    @Str( '', { notNullable: true } ) declare firstName: string
    @Str( '', { notNullable: true } ) declare lastName: string
    @Num( null ) declare birthYear: number | null

    static piniaOptions = {
        persist: true
    }
}