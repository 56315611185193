import { Defaults, Options } from "@/orm/models/Setting"
import BoatClassesRepo from "@/orm/repositories/BoatClassesRepo"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import { useRepo } from "pinia-orm"
import apiRequest from "./httpClient"

export function updateClassesList () {
    const settingsRepo = useRepo( SettingsRepo )

    return apiRequest( settingsRepo.getOption( Options.AuthToken, Defaults.AuthToken ) ).get( '/catalogs/classes' ).then( response => {
        useRepo( BoatClassesRepo ).flush()

        response.data.forEach( ( line ) => {
            const sailClass = useRepo( BoatClassesRepo ).make( {
                id: parseInt( line.id ),
                name: String( line.name ),
                shortcut: String( line.shortcut )
            } )

            if ( Object.prototype.hasOwnProperty.call( line, 'flag' ) )
                sailClass.flag = String( line.flag )

            if ( Object.prototype.hasOwnProperty.call( line, 'crewSize' ) )
                sailClass.crewSize = parseInt( line.crewSize )

            if ( Object.prototype.hasOwnProperty.call( line, 'scoringSystem' ) )
                sailClass.scoringSystem = String( line.scoringSystem )

            if ( Object.prototype.hasOwnProperty.call( line, 'useBoatNames' ) )
                sailClass.useBoatNames = Boolean( line.useBoatNames )
            else
                sailClass.useBoatNames = false

            if ( Object.prototype.hasOwnProperty.call( line, 'useHandicaps' ) )
                sailClass.useHandicaps = Boolean( line.useHandicaps )
            else
                sailClass.useHandicaps = false

            useRepo( BoatClassesRepo ).save( sailClass )
        } )
    } )
}
