import { Plugin } from 'vue'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createPinia } from 'pinia'
import { createORM } from 'pinia-orm'

export const datalayer: Plugin = ( app ) => {
    // implicit data layer manager
    const pinia = createPinia()

    pinia.use( piniaPluginPersistedstate )
    pinia.use( createORM() )

    // apply data layer to passed application
    app.use( pinia )
}
