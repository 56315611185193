<template>
    <q-layout view="hHh lpr fFf">
        <AppBar />

        <SideBar
            @sync-data="syncData()"
            @log-in="logIn()"
            @log-out="logOut()" />

        <q-page-container>
            <router-view
                @sync-data="syncData()"
                @log-in-and-update="logInAndUpdate()" />
        </q-page-container>

        <q-ajax-bar position="bottom" color="accent" size="10px" />
    </q-layout>
</template>

<script setup type="ts">
// Purpose: Describe the whole application and construct main app layout

import Confirmation from "@/components/dialogs/Confirmation.vue"
import InfoBox from "@/components/dialogs/InfoBox.vue"
import LogIn from "@/components/dialogs/LogIn.vue"
import SideBar from "@/components/SideBar.vue"
import { apiLogOut, authenticate } from "@/network/auth"
import { updater } from "@/network/updater"
import { Defaults, Options } from "@/orm/models/Setting"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import { ts } from "@/plugins/i18n-formatted"
import { useRepo } from "pinia-orm"
import { useQuasar } from "quasar"
import { computed } from "vue"
import AppBar from './components/AppBar.vue'

const quasar = useQuasar()

useRepo( SettingsRepo ).triggerActions( Options.Language )

const isAuthenticated = computed( () => ( useRepo( SettingsRepo ).getOption( Options.AuthToken, Defaults.AuthToken ) != '' ) )

function logInAndUpdate ( event ) {
    logIn( event, 3, 0, true )
}

async function syncData ( event, force = false ) {
    if ( !isAuthenticated.value && !force ) {
        quasar.dialog( {
            component: Confirmation,
            componentProps: {
                icon: 'lock',
                question: ts( 'loginBeforeDataUpdate' ),
                yes: ts( 'login' ),
                no: ts( 'justUpdate' )
            }
        } ).onOk( async () => {
            await logIn( event, 3, 0, true )
        } ).onCancel( async () => ( await updater() ) )
    } else {
        await updater()
    }
}

function logIn ( event, attempts = 3, error = 0, updateDataAtTheEnd = false ) {
    quasar.dialog( {
        component: LogIn,
        componentProps: {
            errorMsg: error === 1 ? ts( 'notAuthenticated' ) : ( error === 2 ? ts( 'unknownErrorDuringAuthentication' ) : '' )
        }
    } ).onOk( async ( payload ) => {
        const lastError = await authenticate( payload.username, payload.password )

        if ( !isAuthenticated.value ) {
            if ( attempts > 1 )
                logIn( event, attempts - 1, lastError, updateDataAtTheEnd )
            else
                quasar.dialog( {
                    component: InfoBox,
                    componentProps: {
                        message: ts( 'tooManyAttempts' )
                    }
                } )
        } else {
            if ( updateDataAtTheEnd )
                syncData( event, true )
            else {
                quasar.dialog( {
                    component: Confirmation,
                    componentProps: {
                        icon: 'update',
                        question: ts( 'updateDataNow' )
                    }
                } ).onOk( () => {
                    syncData( event, true )
                } )
            }
        }
    } )
}

function logOut () {
    quasar.dialog( {
        component: Confirmation,
        componentProps: {
            icon: 'logout',
            question: ts( 'reallyLogOut' )
        }
    } ).onOk( () => ( apiLogOut() ) )
}
</script>

<style lang="sass">
body, .q-field, .q-btn, .q-table tbody td, .q-table thead th
  font-size: 1em !important
</style>