<template>
    <div>
        <navigation :label="props.label" />

        <div class="q-pa-lg row items-start q-gutter-md items-stretch justify-center">
            <q-card flat class="column width-dialog-standard" :class="props.pageClass">
                <slot v-if="props.useCustomContent" />
                <qe-form ref="primaryForm" autofocus v-else>
                    <q-card-section>
                        <div class="text-h5 q-mb-sm" v-if="props.useCaption">
                            {{ props.caption.length > 0 ? props.caption : props.label }}
                        </div>

                        <slot />
                    </q-card-section>

                    <q-card-actions align="right" v-if="props.useButtons">
                        <q-btn
                            v-if="useSecondButton"
                            outline color="primary" :label="props.cancelBtn"
                            @click="emits( 'cancel' )" />
                        <q-btn
                            outline color="primary" :label="props.okBtn"
                            @click="okBtnClick()" />
                    </q-card-actions>
                </qe-form>
            </q-card>
        </div>
    </div>
</template>

<script setup lang="ts">
import Navigation from "@/components/parts/navigation.vue"
import QeForm from "@/components/qe/qeForm.vue"
import { ts } from "@/plugins/i18n-formatted"
import { ref } from "vue"

interface Props {
    label: string
    caption?: string
    cancelBtn?: string
    okBtn?: string
    useCaption?: boolean
    useButtons?: boolean
    useSecondButton?: boolean
    useCustomContent?: boolean
    pageClass?: string
}

const props = withDefaults( defineProps<Props>(), {
    label: '',
    caption: '',
    cancelBtn: ts( 'cancel' ),
    okBtn: ts( 'save' ),
    useForm: true,
    useButtons: true,
    useSecondButton: true,
    useCustomContent: false,
    pageClass: ''
} )

const emits = defineEmits( [ 'cancel', 'ok' ] )

const primaryForm = ref()

async function okBtnClick () {
    if ( await primaryForm.value?.validate() )
        emits( 'ok' )
}
</script>

<style lang="sass" scoped>
.width-dialog-standard
  min-width: 512px
  width: 66vw
  max-width: 1280px
</style>
