import { ts } from "@/plugins/i18n-formatted"
import { Dictionary } from "@/utils/dictionary"

const eventTypeTabDef = [ '1', '2', '3', '3C', '3M', '4', '4K', '7', '7P', '8', '12', '12MR', '12MMR', '18', '18MEJ', '24', '24ME', '36', '50', '50ME', '75', '100' ]

const commonTypes = [ '1', '2', '3C', '3M', '4K', '7P', '12MR' ]

export const eventTypeTab: Dictionary<string> = {}

for ( const key of eventTypeTabDef )
    eventTypeTab[ key ] = 'eventType-' + key

export function allEventTypes () {
    const allTypes = []

    for ( const key in eventTypeTab ) {
        const weight = key.replaceAll( /\D+/g, '' )

        allTypes.push( { value: key, label: weight + ' ' + ts( eventTypeTab[ key ] ), weight: weight, common: commonTypes.indexOf( key ) >= 0, lastCommon: false } )
    }

    const list = allTypes.sort( ( a, b ) => ( a.common == b.common ? ( a.weight - b.weight == 0 ? a.label.localeCompare( b.label ) : a.weight - b.weight ) : ( b.common - a.common ) ) )

    list[ list.findLastIndex( eventType => eventType.common ) ].lastCommon = true

    return list

}

export function normalizeEventType ( item ) {
    const weight = item.replaceAll( /\D+/g, '' )

    if ( weight <= 2 )
        return String( weight )

    return item
}