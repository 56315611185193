<template>
    <q-tab-panel name="EventManagementTab">
        <div class="q-mb-md">
            {{ ts( 'removeEventHint' ) }}
        </div>

        <q-form autofocus>
            <q-btn
                :label="ts( 'removeEvent' )" outline color="negative"
                @click="removeEvent()" />
        </q-form>
    </q-tab-panel>
</template>

<script setup lang="ts">
import Confirmation from "@/components/dialogs/Confirmation.vue"
import EventsRepo from "@/orm/repositories/EventsRepo"
import { ts } from "@/plugins/i18n-formatted"
import { useRepo } from "pinia-orm"
import { useQuasar } from "quasar"
import { ref } from "vue"
import { useRoute, useRouter } from "vue-router"

const quasar = useQuasar()
const router = useRouter()
const route = useRoute()

const event = ref( useRepo( EventsRepo ).getEvent( String( route.params.eventId ) ) )

useRepo( EventsRepo ).selectEvent( event.value.id )

function removeEvent () {
    quasar.dialog( {
        component: Confirmation,
        componentProps: {
            icon: 'delete',
            question: ts( 'reallyRemoveEvent', { eventTitle: event.value.fullTitle } )
        }
    } ).onOk( () => {
        useRepo( EventsRepo ).destroy( event.value.id )

        router.push( { name: 'MainPage' } )
    } )
}
</script>