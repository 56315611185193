import { Repository } from 'pinia-orm'
import Venue from "@/orm/models/Venue"

export default class VenuesRepo extends Repository {
    use = Venue

    venuesList () {
        return this.all()
            .map( ( venue ) => ( { label: venue.localizedName, value: venue.id } ) )
            .sort( ( a, b ) => a.label.localeCompare( b.label ) )
    }

    venueIdByName ( name ) {
        return this.where( 'name', name ).orWhere( 'nameEn', name ).first()?.id
    }
}